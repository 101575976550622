@use 'variables';
@use 'mixins/content';

body {
  font-family: "IBM Plex Mono", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
  text-rendering: optimizeLegibility;
  margin: 0;
  font-size: 55%; // convert from adobe XD to real application (62.5% * 1.4 / 1.6)
  overflow: hidden;
}

.resize-animation-stopper * {
  animation: none !important;
  transition: none !important;
}

pre {
  font-family: "IBM Plex Mono", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
  text-rendering: optimizeLegibility;
  margin: 0;
}

a {
  font-weight: 400;
  text-decoration: underline;
  text-underline-offset: 6px;
  text-decoration-thickness: 0.5px;
  color: black;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

input {
  &:focus {
    outline: none;
  }
  &[type="checkbox"], &[type="radio"], &[type="submit"], &[type="button"] {
    &:focus {
      outline: auto;
    }
  }
}

textarea {
  &:focus {
    outline: none;
  }
}

hr {
  height: 0.5px;
  background-color: #000;
  margin: 0px auto;
  border: none;
}
